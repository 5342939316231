import React from 'react';
import { array, object } from 'prop-types';
import styles from './RelatedBlog.module.scss';
import { RichText } from 'prismic-reactjs';
import Articles from '../Articles/Articles';

const RelatedBlog = ({ primary, filteredArticles }) => {
  const { title } = primary;
  return (
    <section className={styles.relatedBlog}>
      <div className={styles.container}>
        <div className={styles.title}>
          <RichText render={title.richText} />
        </div>
        <div className={styles.blogPreview}>
          <Articles filteredArticles={filteredArticles} />
        </div>
      </div>
    </section>
  );
};

RelatedBlog.propTypes = {
  node: object,
  primary: object,
  filteredArticles: array,
};

export default RelatedBlog;
