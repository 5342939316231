import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import style from './NewSolutionPage.module.scss';
import 'swiper/swiper.scss';
import NewHero from '../../components/NewHero';
import CardBanner from './components/CardBanner/CardBanner';
import CookieSteps from './components/CookieSteps/CookieSteps';
import SolutionQuestions from './components/SolutionQuestions/SolutionQuestions';
import RelatedBlog from './components/RelatedBlog/RelatedBlog';
import BreadcrumbsSemanticMarkup from '../../components/BreadcrumbsMarkup/BreadcrumbsMarkup';
import FaqSemanticMarkup from '../../components/FaqSemanticMarkup/FaqSemanticMarkup';

const NewSolutionPage = ({
  current: body,
  pageUid,
  canonical,
  metatitle,
  blogDetails,
}) => {
  const [isData, setIsData] = useState([]);

  const convertedPageUid = pageUid
    .replace(/-/g, ' ')
    .split(/\s+/)
    .filter(Boolean)
    .map((item) => `${item}`);

  const filteredArticles = blogDetails?.filter(({ node }) => {
    const searchData = JSON.stringify({
      uid: node?.uid,
      tags: node?.tags,
      data: node?.data,
    }).toLowerCase();

    return searchData.includes(`${convertedPageUid[0]} ${convertedPageUid[1]}`);
  });

  const filteredArticlesWithName = blogDetails?.filter(({ node }) => {
    const searchData = JSON.stringify({
      tags: node?.tags,
      uid: node?.uid,
      data: node?.data,
    }).toLowerCase();

    return searchData.includes(convertedPageUid[0]);
  });

  useEffect(() => {
    if (filteredArticles.length === 0) {
      setIsData(filteredArticlesWithName);
    } else {
      setIsData(filteredArticles);
    }
  }, []);

  const questions = body.filter((item) => item.slice_type === 'questions');
  const contentQuestions = body.filter((item) => item.slice_type === 'content');
  const questionsSliceFaqLists = questions.map((element) => element.items);
  const contentSliceFaqLists = contentQuestions.map((element) => element.items);
  const questionsSliceFaqList = questionsSliceFaqLists.flat();
  const contentSliceFaqList = contentSliceFaqLists.flat();
  const generalFaqList = [...questionsSliceFaqList, ...contentSliceFaqList];

  const handleMoveToWhatYouNeed = () => {
    const divElement = document.getElementById('what_you_need_make');
    divElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={style.SolutionPage}>
      {body.map((section, index) => {
        switch (section.slice_type) {
          case 'hero':
            return (
              <NewHero
                {...section}
                key={`${section.slice_type}${index}`}
                handleMoveToWhatYouNeed={handleMoveToWhatYouNeed}
              />
            );
          case 'card_banner':
            return (
              <CardBanner {...section} key={`${section.slice_type}${index}`} />
            );
          case 'cookiesteps':
            return (
              <CookieSteps {...section} key={`${section.slice_type}${index}`} />
            );
          case 'questions':
            return (
              <SolutionQuestions
                {...section}
                key={`${section.slice_type}${index}`}
              />
            );
          case 'relatedblog':
            return (
              <RelatedBlog
                {...section}
                filteredArticles={isData.length !== 0 ? isData : blogDetails}
                key={`${section.slice_type}${index}`}
              />
            );
          default:
            throw new Error(`Unknown section type: ${section.slice_type}`);
        }
      })}
      <FaqSemanticMarkup questions={generalFaqList} />
      <BreadcrumbsSemanticMarkup
        pageTitle={metatitle?.text}
        pageUrl={canonical?.text}
      />
    </div>
  );
};

NewSolutionPage.propTypes = {
  current: PropTypes.array.isRequired,
  mainSection: PropTypes.array,
  pageUid: PropTypes.string,
  canonical: PropTypes.object.isRequired,
  metatitle: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  blogDetails: PropTypes.array,
};

export default NewSolutionPage;
